import TagManager from '@sooro-io/react-gtm-module';
import {config} from '../config';
import {useEffect} from 'react';

export function useGtm() {
    useEffect(() => {
        if (config.gtmId) {
            TagManager.initialize({
                gtmId: config.gtmId,
                dataLayer: {
                    page: 'profile',
                },
                nonce: config.nonce,
            });
        }
    }, []);
}
