import {initReactI18next} from 'react-i18next';
import {defaultLocale, locales} from './lib/locales';
import * as adminLangs from '../translations/admin';
import * as appLangs from '../translations/app';
import moment from 'moment';
import 'moment/dist/locale/fr';

import {appendNS, createI18N, createNS} from '@alchemy/i18n';

const resources = createNS(appLangs, 'app', locales);
appendNS(resources, 'admin', adminLangs, locales);

const i18n = createI18N({
    initReactI18next,
    resources,
    defaultLocale: defaultLocale,
    locales,
    defaultNS: 'admin',
    onLanguageChanged: lng => {
        if (lng) {
            moment.locale(lng);
        }
    },
    initOptions: {
        compatibilityJSON: 'v4',
    },
});

if (i18n.language) {
    moment.locale(i18n.language);
}

export default i18n;
